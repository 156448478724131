import React from 'react'
import { useContext } from 'react';
import { Context } from './Context';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Almunailist from './components/Almunailist';
import Datalist from './components/Datalist';
import Logout from './components/Logout';
import Userlist from './components/Userlist';
import Notfound from './components/Notfound'
import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
const Routing = () => {
    const { usr, updateUser } = useContext(Context);
    console.log('user data in route')
    console.log(usr.success)
    let user = usr
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="login" element={<Login />} />
                <Route exact path="dashboard" element={user?.success == "Success" ? <Dashboard /> : <Navigate replace to={"/"} />} />
                <Route exact path="logout" element={user?.success == "Success" ? <Logout /> : <Navigate replace to={"/"} />} />
                <Route exact path="userlist" element={user?.success == "Success" ? <Userlist /> : <Navigate replace to={"/"} />} />
                <Route exact path="datalist" element={user?.success == "Success" ? <Datalist /> : <Navigate replace to={"/"} />} />

                <Route
                    path="*"
                    element={
                        <main style={{ padding: "1rem" }}>
                            <Notfound />
                        </main>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default Routing