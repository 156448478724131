import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { Context } from '../Context';
const Logout = () => {
    const navigate = useNavigate();
    const { usr, updateUser } = useContext(Context);
    const logoutUser = () => {
        updateUser({})
        localStorage.removeItem("userdata")
        navigate("/login")
    }
    useEffect(() => {
        logoutUser()
    }, [])
    return (
        <div align="center">Logging out.....</div>
    )
}

export default Logout