import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom'
const Header = ({ u }) => {
    const [user, setUser] = useState({})

    const getUser = () => {

        console.log('user data header -', u)
        //alert(u?.success)
        if (u != null) {
            setUser((user) => u)
        }
    }
    useEffect(() => {
        //    getUser()
    }, [])
    return (
        <Navbar bg="dark" expand="lg">
            <Container>
                <Navbar.Brand className="color-white">TFFS Almuni</Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {u?.success != "Success" && <Nav.Link className="color-white"><Link to="/" className="color-white">Home</Link></Nav.Link>}
                        {u?.success == "Success" && <Nav.Link className="color-white"><Link to="/dashboard" className="color-white">Dashboard</Link></Nav.Link>}
                        {u?.success == "Success" && <Nav.Link className="color-white"><Link to="/datalist" className="color-white">Alumni</Link></Nav.Link>}

                    </Nav>
                </Navbar.Collapse>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {u?.success == "Success" && <Nav.Link className="color-white"><Link to="/logout" className="color-white">Logout</Link></Nav.Link>}
                    </Navbar.Text>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    )
}

export default Header