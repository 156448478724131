import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Header from './Header';
import { Row, Col } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import axios from 'axios';
import Url from '../constant/Url';
import { useContext } from 'react';
import { Context } from '../Context';
import { Link } from 'react-router-dom'

let ld = JSON.parse(localStorage.getItem('userdata'))

const Dashboard = () => {
  const { usr, updateUser } = useContext(Context);
  let user = usr
  const [alumni, setAlumni] = useState(0)
  const [users, setUsers] = useState(0)
  const [dashdata, setDashdata] = useState({})
  const getdashdata = () => {


    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: Url.baseUrl + 'dashboard/',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDashdata(dashdata => response.data)
      })
      .catch((error) => {
        console.log(error);
      });


  }
  useEffect(() => {
    console.log('user data dashboard ', ld)
    console.log('user context data dashboard ')
    console.log(user)
    getdashdata()
  }, [])

  useEffect(() => {
    setAlumni(dashdata.alumni)
    setUsers(dashdata.user)
  }, [dashdata])


  return (
    <center>
      <Card className="page">
        <Card.Body>
          <Header u={user} />
          <Row>
            <Col>
              <Card className='mystyle' style={{ width: '99%' }}>
                <Card.Body>
                  <Card.Title>Alumni Count</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"> <Badge className='mybadge' bg="primary">
                    {alumni}
                  </Badge>{' '}</Card.Subtitle>
                  <Card.Text>
                    This shows the number of Alumni who have registered.
                  </Card.Text>
                  <Card.Link><Link to="/datalist">View Data</Link></Card.Link>

                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className='mystyle' style={{ width: '99%' }}>
                <Card.Body>
                  <Card.Title>Users Count</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"> <Badge className='mybadge' bg="primary">
                    {users}
                  </Badge>{' '}</Card.Subtitle>
                  <Card.Text>
                    This shows the number of users.
                  </Card.Text>
                  <Card.Link ><Link to="/userlist">View Data</Link></Card.Link>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </center>
  )
}

export default Dashboard