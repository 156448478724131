import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Header from './Header';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import axios from 'axios';
import Url from '../constant/Url';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { useContext } from 'react';
import { Context } from '../Context';

const Login = () => {
    let u = localStorage.getItem('userdata')
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [loader, setLoader] = useState(false)

    const { usr, updateUser } = useContext(Context);

    const navigate = useNavigate();
    const validate = () => {
        if (username == "" || password == "") {
            setError("Please enter valid username or password")
            return false
        }
        else {
            setError("")
            return true
        }
    }
    const dologin = () => {
        if (validate()) {
            setLoader(true)
            const FormData = require('form-data');
            let data = new FormData();
            data.append('username', username);
            data.append('password', password);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Url.baseUrl + 'login/',
                headers: data.getHeaders ? data.getHeaders() : { 'Content-Type': 'multipart/form-data' },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setLoader(false)
                    if (response.data.success == "Success") {
                        localStorage.setItem('userdata', JSON.stringify(response.data))
                        updateUser(response.data)
                        navigate('/dashboard')
                    }
                    else {
                        setError("Invalid User Data")
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    return (
        <center>
            <Card className="page">
                <Card.Body>
                    <Header u={u} />

                    <Form className="frm">
                        {error.length > 0 && <Alert key={1} variant={'danger'}>
                            {error}

                        </Alert>}
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="mb-3"
                        >
                            <Form.Control type="email" placeholder="name@example.com" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Password">
                            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                        </FloatingLabel>
                        <br />
                        <Button variant="primary" type="button" onClick={dologin} disabled={loader}>
                            Login
                        </Button>

                        {loader && <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    </Form>

                </Card.Body>
            </Card>
        </center>
    )
}

export default Login