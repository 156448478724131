import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Header from './Header';
import { Row, Col } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import axios from 'axios';
import Url from '../constant/Url';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ResponsivePagination from 'react-responsive-pagination';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { Context } from '../Context';

let ld = JSON.parse(localStorage.getItem('userdata'))
const Datalist = () => {
  let pp = 0
  const { usr, updateUser } = useContext(Context);
  ld = usr
  const [alumni, setAlumni] = useState([])
  const [users, setUsers] = useState(0)
  const [dashdata, setDashdata] = useState({})
  const [key, setKey] = useState('mypassword')
  const [word, setWord] = useState('-')
  const [page, setPage] = useState('1')
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0)
  const totalPages = 20;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState({})

  const navigate = useNavigate();

  const getalumnidata = (p) => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', key);
    data.append('word', word);
    data.append('page', p);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + 'datalist/',
      headers: data.getHeaders ? data.getHeaders() : { 'Content-Type': 'multipart/form-data' },
      data: data
    };

    axios.request(config)
      .then((response) => {
        //  console.log(JSON.stringify(response.data));
        setAlumni(alumni => response.data.data)
        setPages(pages => response.data.pages)
      })
      .catch((error) => {
        console.log(error);

      });

  }
  useEffect(() => {
    console.log('user data datalist ', ld)
    getalumnidata(1)
  }, [])
  useEffect(() => {
    pp = ((currentPage - 1) * 20)
  }, [currentPage])
  const searchbykeyword = () => {
    console.log(word)
    getalumnidata(1)
  }


  const loadpage = (n) => {
    console.log(n)
    setCurrentPage(currentPage => n)
    getalumnidata(n)
  }
  const resetdata = () => {
    //window.location.href = window.location.href
    navigate('/dashboard')
    setTimeout(() => {
      navigate('/datalist')
    }, 300)

  }
  const showdetail = (item) => {
    setDetails(details => item)
    handleShow()
  }

  const deldata = (item) => {
    var y = window.confirm("Are you sure you want to delete ?")
    if (y) {
      const FormData = require('form-data');
      let data = new FormData();
      data.append('id', item.id.toString());
      console.log('current id - ' + item.id.toString());
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Url.baseUrl + 'removedata/',
        headers: data.getHeaders ? data.getHeaders() : { 'Content-Type': 'multipart/form-data' },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          getalumnidata(currentPage)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <center>
      <Card className="page">
        <Card.Body>
          <Header u={ld} />
          <Row className='mt-3'>
            <Col>
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search for name/contact"
                  className="me-2"
                  aria-label="Search"
                  onChange={e => { setWord(word => e.target.value) }}
                />
                <Button onClick={searchbykeyword} variant="outline-success">Search</Button>{' '}&nbsp;
                <Button onClick={resetdata} variant="outline-success">Reset</Button>
              </Form>

            </Col>
            <Col>
              <ResponsivePagination
                current={currentPage}
                total={pages}
                onPageChange={(n) => { loadpage(n) }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover className='mt-3'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Contact Info</th>
                    <th>Grad Year</th>
                    <th>Occupation</th>
                    <th>Location</th>
                    <th>Expertise</th>
                  </tr>
                </thead>
                <tbody>
                  {alumni.length > 0 && alumni.map((item, index) => {
                    pp++
                    return <tr>
                      <td>{item.data_id}</td>
                      <td><strong>{item.name}</strong> <br /> <Button className="mt-3" onClick={showdetail.bind(this, item)} variant="success" size="sm">View more</Button>
                        <br /> <Button className="mt-2" onClick={deldata.bind(this, item)} variant="danger" size="sm">Delete</Button>
                      </td>
                      <td>{item.contact_info}</td>
                      <td>{item.grad_year}</td>
                      <td>{item.occupation}</td>
                      <td>{item.location}</td>
                      <td>{item.expertise}</td>
                    </tr>

                  })}

                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{details?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-scroll'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Contact Info</th>
                  <th>{details?.contact_info}</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Grad Year</td>
                  <td>{details?.grad_year}</td>

                </tr>
                <tr>
                  <td>Occupation</td>
                  <td>{details?.occupation}</td>

                </tr>
                <tr>
                  <td>DOB</td>
                  <td>{details?.dob}</td>

                </tr>
                <tr>
                  <td>Degree</td>
                  <td>{details?.degree}</td>

                </tr>
                <tr>
                  <td>Company</td>
                  <td>{details?.company}</td>

                </tr>
                <tr>
                  <td>Position</td>
                  <td>{details?.position}</td>

                </tr>
                <tr>
                  <td>Expertise</td>
                  <td>{details?.expertise}</td>

                </tr>
                <tr>
                  <td>Location</td>
                  <td>{details?.location}</td>

                </tr>
                <tr>
                  <td>Linkedin</td>
                  <td>{details?.linkedin}</td>

                </tr>
                <tr>
                  <td>Bio</td>
                  <td>{details?.bio}</td>

                </tr>
                <tr>
                  <td>Career so far</td>
                  <td>{details?.career_so_far}</td>

                </tr>
                <tr>
                  <td>Impact of degree</td>
                  <td>{details?.impact_of_degree}</td>

                </tr>
                <tr>
                  <td>Advice</td>
                  <td>{details?.advice}</td>

                </tr>

                <tr>
                  <td>Shared Experience</td>
                  <td>{details?.share}</td>

                </tr>
                <tr>
                  <td>Connect with school</td>
                  <td>{details?.connect_with_school}</td>

                </tr>
                <tr>
                  <td>Collaboration</td>
                  <td>{details?.collaboration}</td>

                </tr>


              </tbody>
            </Table>


          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </center>
  )
}

export default Datalist