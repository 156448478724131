import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap'
import nf from "../img/not-found.png"
const Notfound = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <center>
                        <img src={nf} className="nf" /><br />
                        <Link to="/" className="themelnk">GO BACK</Link>
                    </center>
                </Col>
            </Row>
        </Container>
    )
}

export default Notfound