import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Header from './Header';
import { Row, Col } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import axios from 'axios';
import Url from '../constant/Url';
import Table from 'react-bootstrap/Table';

import { useContext } from 'react';
import { Context } from '../Context';

let ld = JSON.parse(localStorage.getItem('userdata'))

const Userlist = () => {

    const [users, setUsers] = useState([])
    const { usr, updateUser } = useContext(Context);
    ld = usr
    const getusers = () => {
        const FormData = require('form-data');
        let data = new FormData();
        data.append('key', 'mypassword');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: Url.baseUrl + 'userlist/',
            headers: data.getHeaders ? data.getHeaders() : { 'Content-Type': 'multipart/form-data' },

            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setUsers(users => response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getusers()
    }, [])

    return (
        <center>
            <Card className="page">
                <Card.Body>
                    <Header u={ld} />
                    <Row>
                        <Col>
                            <Card className='mystyle' style={{ width: '99%' }}>
                                <Card.Body>
                                    <Card.Title>Users</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Shows the list of users</Card.Subtitle>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Username</th>
                                                <th>Password</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                users.length > 0 && users.map((item, index) => {
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.password}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.blocked == "0" ? '' : 'User Blocked'}</td>
                                                    </tr>
                                                })
                                            }


                                        </tbody>
                                    </Table>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </center>
    )
}

export default Userlist