import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Routing from './Routing';
import { Context } from './Context';
let uu = {}
var x = null
function App() {
  const [usr, setUsr] = useState({})
  const value = {};
  const updateUser = (x) => {
    setUsr(usr => x)
  }
  const doData = () => {
    x = setInterval(() => {
      uu = JSON.parse(localStorage.getItem('userdata'))
      console.log('user data -', uu)
      setUsr(u => uu)
    }, 2000)
  }
  useEffect(() => {
    //  doData()
    uu = JSON.parse(localStorage.getItem('userdata'))
    console.log('user data app start')
    console.log(uu)
    if (uu?.success.toString() == "Success") {
      updateUser(usr => uu)
    }

    return () => {
      //  clearInterval(x)
    }
  }, [])

  return (
    <Context.Provider value={{ usr, updateUser }}>
      <Routing />
    </Context.Provider>
  );
}

export default App;
